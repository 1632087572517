.pageTitle {
    text-align: center;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    padding-bottom: 30px;
}

.filter button {
    padding: 5px 20px;
}

.singleproject {
    /*background-color: rgba(157, 175, 91, 0.2);*/
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
}

.projectListings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.projectListingsMargin {
    margin: 30px 250px;
}

.text {
    margin: 10px 20px;
}

.text h1 {
    font-size: 25px;
}

.text h2 {
    font-size: 15px;
}

.logo {
    margin: 20px;
}

.buttSelected {
    color: white;
    background-color: #9DAF5B;
}

.technology {
    font-style: italic;
}

.more a {
    color: #9DAF5B;
}

@media (max-width: 1024px) {
    .projectListingsMargin {
        margin: 30px 150px;
    }

    .pageTitle {
        font-size: 30px;
    }

    .filter button {
        font-size: 15px;
    }

    .projectListingsMargin {
        margin: 30px 100px;
    }

    .text h1 {
        font-size: 20px;
    }
    
    .text h2 {
        font-size: 12px;
    }

    .logo img {
        width: 125px;
    }
}  

@media (max-width: 768px) {
    .pageTitle {
        font-size: 25px;
    }

    .filter button {
        font-size: 12px;
    }

    .text h1 {
        font-size: 17px;
    }
    
    .text h2 {
        font-size: 12px;
    }

    .logo img {
        width: 115px;
    }
}  

@media (max-width: 600px) {
    .filter {
        flex-wrap: wrap;
        padding-bottom: 10px;
        margin: 0px 20px;
    }

    .singleproject {
        flex-direction: column;
    }

    .projectListingsMargin {
        margin: 30px 30px;
    }

    .filter button {
        font-size: 12px;
    }

    .logo img {
        width: 100px;
    }
} 

@media (max-width: 320px) {
    .projectListingsMargin {
        margin: 30px 20px;
    }

    .logo img {
        width: 80px;
    }
} 