footer {
    text-align: center;
    font-size: 14px;
    font-family: "Roboto Slab";
    color: gray;
    margin: auto 20px;
}

@media (max-width: 1024px) {
    footer {
        font-size: 11px;
    }
} 