.contactForm {
    width: 80%;
    margin: 100px auto;
}

.contactForm h1 {
    font-size: 35px;
}

label {
    font-family: 'Roboto Slab';
    font-size: 25px;
}

input {
    font-family: 'Roboto Slab';
    border: none;
    border-bottom: 2px solid #9DAF5B;
    background-color: transparent;
    margin: 5px;
    font-size: 25px;
    text-align: center;
}

textarea {
    font-family: 'Roboto Slab';
    font-size: 25px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #9DAF5B;
    width: 95%;
    resize: none;
    margin-top: 10px;
}

.contactGreen {
    margin: 50px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    padding: 50px;
}

form {
    color: black;
    line-height: 75px;
}

*:focus {
    outline: none;
}

button {
    color: #9DAF5B;
    background-color: white;
    border: 2px solid #9DAF5B;
    font-family: "Roboto Slab";
    font-size: 20px;
    border-radius: 5px;
    padding: 10px 40px;
}

button:hover {
    background-color: #9DAF5B;
    color: white;
}

.centerUnder {
    width: 100%;
    text-align: center;
}

.centerUnderText {
    text-align: center;
    font-family: 'Roboto Slab Bold';
    font-size: 20px;
}

.headerOne {
    margin-left: 7vh;
}

@media (max-width: 768px) {
    label {
        font-size: 20px;
    }

    input {
        font-size: 20px;
        width: 40%;
    }

    textarea {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .contactForm {
        width: 80%;
        margin: 20px auto 100px;
    }

    .contactForm h1 {
        font-size: 25px;
    }

    label {
        font-size: 15px;
    }

    input {
        font-size: 14px;
        width: 40%;
    }

    textarea {
        font-size: 14px;
    }

    .contactGreen {
        margin: 10px;
        padding: 40px;
    }

    form {
        line-height: 35px;
    }

    button {
        font-size: 14px;
        border-radius: 5px;
        padding: 5px 20px;
    }

    .headerOne {
        margin-left: 2vh;
    }

    .contactForm {
        width: 90%;
    }

    .centerUnderText {
        font-size: 14px;
    }
}