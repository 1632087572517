nav {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 5px 0 5px;
    padding: 15px 50px 15px;
}

header {
    position: sticky;
    top: -100px;
    transition: 0.5s;
    z-index: 1000;
}

header.active {
    top: 0px;
    transition: top 1.5s, background-color 0s, box-shadow 0s;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.leftNav, .rightNav {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.leftNav {
    font-size: 25px;
    font-family: "Roboto Slab";
}

.rightNav li {
    display: flex;
    align-self: center;
}

.rightNav li a {
    display: flex;
}

.activeLink {
    color: #9DAF5B;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

@media (max-width: 1024px) {
    .leftNav {
        font-size: 20px;
    }
}  

@media (max-width: 600px) {
    nav {
        margin: 10px 0 15px;
        padding: 15px 35px 15px;
    }

    .rightNav {
        gap: 10px;
    }

    .github, .linkedin {
        width: 25px;
    }

    .leftNav {
        font-size: 20px;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        z-index: 2;
    }

    .leftNav {
        position: fixed;
        left: -100%;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.95);
        top: 0rem;
        width: 100%;
        text-align: center;
        transition: 0.3s;
        height: 100%;
        z-index: 1;
        justify-content: center;
        gap: 20px;
    }

    .leftNav.active {
        left: 0px;
    }

    .bar.gone.active {
        opacity: 0;
    }

    .bar.left.active {
        transform: translateY(8px) rotate(45deg);
    }

    .bar.right.active {
        transform: translateY(-8px) rotate(-45deg);
    }
   
}  

@media (max-width: 320px) {
    nav {
        margin: 5px 0 5px;
    }
}
