.green {
    color: #9DAF5B;
}

.content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.homepageInternships {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 50px;
}

.homepageInternships ul {
    margin-left: -20px;
} 

.aboutMe, .experience, .resume {
    width: 55%;
    margin-bottom: 100px;
}

.resume button {
    color: #9DAF5B;
    background-color: white;
    border: 2px solid #9DAF5B;
    font-family: "Roboto Slab";
    font-size: 20px;
    border-radius: 5px;
    padding: 10px 40px;
}

.resume button:hover {
    background-color: #9DAF5B;
    color: white;
}

.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
    margin-bottom: 30px;
}

.leftMain h1 {
    font-size: 45px;
    font-family: 'Roboto Slab Bold'
}

.leftMain h2 {
    display: flex;
    flex-direction: row;
    font-size: 25px;
    font-family: 'Roboto Slab';
    gap: 7px;
    margin: 0px;
}

.wrap {
    color: #9DAF5B;
}

.rightMain {
    filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.25))
}

.rightMain img {
    width: 400px;
    clip-path: circle();
}

.logos {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.logos img {
    width: 100px;
}

.resButton {
    width: 100%;
    text-align: center;
}

.whiteBack {
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


@media (max-width: 1024px) {
    .leftMain h1 {
        font-size: 35px;
    }
    
    .leftMain h2 {
        font-size: 20px;
    }

    .rightMain img {
        width: 300px;
    }

    .aboutMe, .experience, .resume {
        width: 65%;
    }
}  

@media (max-width: 768px) {
    .aboutMe, .experience, .resume {
        width: 80%;
        margin-bottom: 60px;
    }

    .main {
        flex-direction: column;
        gap: 0;
        margin-bottom: 0;
        margin-top: 20px;
    }
}

@media (max-width: 425px) {
    .main {
        margin-top: 0;
    }

    .leftMain h1 {
        font-size: 35px;
    }

    .rightMain img {
        margin-top: -30px;
        width: 250px;
    }

    .aboutMe, .experience, .resume {
        width: 80%;
    }

    .logos img {
        width: 70px;
        margin-bottom: 25px;
    }

    .resButton button {
        font-size: 14px;
    }

    .whiteBack {
        padding: 20px 25px;
    }
}  

@media (max-width: 375px) {
    .whiteBack {
        padding: 20px;
    }

    .aboutMe, .experience, .resume {
        width: 85%;
    }
}
